import { dashConditionForUnitValues, dashConditionForPercentageValues, decimalFormatter } from '@/helpers/formatters'

export default {
  animateRows: true,
  columnDefs: [
    {
      headerName: "Article Details",
      children: [
        {
          headerName: 'ARTICLE ID',
          field: 'Article ID',
          pinned: 'left',
          filter: 'agTextColumnFilter',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          sortable: true,
          columnGroupShow: "close",
          unSortIcon: true,
        },
        {
          headerName: 'ARTICLE DESCRIPTION',
          field: 'Article Description',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed"
        },
        {
          headerName: 'CHAR COLLECTION',
          field: 'collection',
          filter: 'agTextColumnFilter',
          sortable: true,
          columnGroupShow: "closed",
          unSortIcon: true,
        },
        {
          headerName: 'MERCH CAT',
          field: 'merch_cat_descr',
          filter: 'agTextColumnFilter',
          sortable: true,
          columnGroupShow: "closed",
          unSortIcon: true,
        },
        {
          headerName: 'CATEGORY',
          field: 'category_descr',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
          
        },
        {
          headerName: 'SHOP',
          field: 'shop_descr',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
          
        },
        {
          headerName: 'BUSINESS GROUP',
          field: 'business_descr',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
          
        }
      ]
    },
    {
      headerName: "Store Details",
      children: [
        {
          headerName: 'STORE ID',
          field: 'Store ID',
          pinned: 'left',
          filter: 'agTextColumnFilter',
          sortable: true,
          columnGroupShow: "close",
          unSortIcon: true,
        },
        {
          headerName: 'AVL',
          field: 'AVL',
          filter: 'agTextColumnFilter',
          sortable: true,
          columnGroupShow: "closed",
          unSortIcon: true,
        },
        {
          headerName: 'CORP TIER',
          field: 'corprate_tier',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
        },
        {
          headerName: '# OF LMS ',
          field: 'Nbr LMs',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
        },
        {
          headerName: 'LAYOUT MODULE',
          field: 'First LM',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
        },
        {
          headerName: 'SUPPLYING DC',
          field: 'Supplying DC',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
          cellRenderer: function(params) {
            return params.data["Supplying DC"] == "DSD/DC09" ? "NA" : params.data["Supplying DC"];
          }
        },
        {
          headerName: 'REGION',
          field: 'region',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
        },
        {
          headerName: 'DISTRICT',
          field: 'sales_district',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
        },
        {
          headerName: 'STATE',
          field: 'state',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
        },
        {
          headerName: 'CITY',
          field: 'city',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
        },
        {
          headerName: 'ZIP CODE',
          field: 'postal_code',
          filter: 'agTextColumnFilter',
          sortable: true,
          unSortIcon: true,
          columnGroupShow: "closed",
        },
      ]
    },
    {
      headerName: 'STORE OH',
      field: 'Store OH',
      filter: false,
      sortable: true,
      unSortIcon: true,
    },
    {
      headerName: 'STORE OO',
      field: 'Store OO',
      filter: false,
      sortable: true,
      unSortIcon: true,
    },
    {
      headerName: 'ALLOCATION',
      field: 'Allocation',
      cellStyle: {'background-color': '#bbedc0'},
      filter: false,
      sortable: true,
      unSortIcon: true
    },
    {
      headerName: 'TOTAL Fill',
      field: 'Total Fill',
      cellStyle: {'background-color': '#F4F6F8', 'border-left': '1px solid grey'},
      filter: false,
      sortable: true,
      unSortIcon: true,
      valueFormatter: decimalFormatter
    },
    {
      headerName: 'PACK',
      field: 'Pack',
      filter: false,
      sortable: true,
      unSortIcon: true
    },
    {
      headerName: 'REF STORE I',
      field: 'Reference_Store_1',
      filter: true,
      sortable: true,
      unSortIcon: true,
      cellStyle: { "text-align": "right" }
    },
    {
      headerName: 'SALES BASIS 1',
      field: 'Sales Basis1',
      filter: false,
      valueFormatter: dashConditionForUnitValues,
      sortable: true,
      unSortIcon: true
    },
    {
      headerName: 'REF STORE II',
      field: 'Reference_Store_2',
      filter: true,
      sortable: true,
      unSortIcon: true,
      cellStyle: { "text-align": "right" }
    },
    {
      headerName: 'INDEX',
      field: 'Store_Index',
      filter: true,
      sortable: true,
      unSortIcon: true,
      cellStyle: { "text-align": "right" }
    },
    {
      headerName: 'SALES BASIS 2',
      field: 'Sales Basis2',
      filter: false,
      valueFormatter: dashConditionForUnitValues,
      sortable: true,
      unSortIcon: true
    },{
      headerName: 'SALES BASIS 1 % CONTRIBUTION',
      field: 'Sales Basis 1 % contribution',
      filter: false,
      valueFormatter: dashConditionForPercentageValues,
      sortable: true,
      unSortIcon: true
    },{
      headerName: 'SALES BASIS 2 % CONTRIBUTION',
      field: 'Sales Basis 2 % contribution',
      filter: false,
      valueFormatter: dashConditionForPercentageValues,
      sortable: true,
      unSortIcon: true
    },
    //{
    //   headerName: ' SALES HISTORY ABSOLUTE NUMBERS',
    //   field: 'Sales History',
    //   filter: false,
    //   sortable: true,
    //   unSortIcon: true,
    //   cellStyle:{
    //     'background-color': '#bbedc0'
    //   }
    // },
    {
      headerName: 'PERCENTAGE CONTRIBUTION',
      field: '% Allocation Based on Sales History',
      valueFormatter: dashConditionForPercentageValues,
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellStyle:{
        'background-color': '#bbedc0'
      }
    },
    {
      headerName: 'MIN',
      field: 'Min',
      filter: false,
      sortable: true,
      unSortIcon: true
    },
    {
      headerName: 'MAX',
      field: 'Max',
      filter: false,
      sortable: true,
      unSortIcon: true,
    }
  ]
}
