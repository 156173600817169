<template>
  <div>
    <vue-element-loading
      width="200"
      :active="getLoader"
      spinner="bar-fade-scale"
      text="This step may take some time. Thank you for your patience."
    />
    <div class="animated fadeIn" v-if="getJsonData">
      <b-card>
        <div slot="header">
          <strong>Completed Allocation: {{getJsonData.allocationBasics.name}}</strong>
        </div>
        <!-- <b-row align-h="start" class="p-2">
        <b-col cols="3">
          <b-form-select v-model="selectedAllocation" :options="options" />
        </b-col>
        </b-row>-->
        <b-row style="padding: 1rem; margin-top: 1rem;">
          <b-col md="6">
            <b-form-group
              id="exampleInputGroup2"
              label-cols="3"
              label-cols-lg="3"
              label="Allocation Name:"
              label-for="exampleInput2"
            >
              <b-form-input
                id="exampleInput2"
                type="text"
                v-model="getJsonData.allocationBasics.name"
                required
                placeholder="Enter Allocation Name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="exampleInputGroup2"
              label-cols="3"
              label-cols-lg="3"
              label="Created By:"
              label-for="exampleInput2"
            >
              <b-form-input id="exampleInput2" type="text" v-model="userName" required disabled />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="exampleInputGroup2"
              label-cols="3"
              label-cols-lg="3"
              label="Created On:"
              label-for="exampleInput2"
            >
              <b-form-input
                id="exampleInput2"
                type="text"
                v-model="createdOnDateFormat"
                required
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="exampleInputGroup2"
              label-cols="3"
              label-cols-lg="3"
              label="Comments:"
              label-for="exampleInput2"
              style="height: 100%;"
            >
              <b-form-textarea
                id="exampleInput2"
                v-model="getJsonData.allocationBasics.comments"
                required
                disabled
                placeholder="Enter comment"
                style="height:80%"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="exampleInputGroup2"
              label-cols="3"
              label-cols-lg="3"
              label="Push:"
              label-for="exampleInput2"
            >
              <b-form-input
                id="exampleInput2"
                type="text"
                v-model="getJsonData.allocationBasics.push"
                required
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="exampleInputGroup2"
              label-cols="3"
              label-cols-lg="3"
              label="Uploaded Info: "
              label-for="exampleInput2"
            >
              <div
                style="margin-top: 0.5rem;font-weight:600;"
              >{{getJsonData.allocationBasics.fileName}}</div>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row  class="p-2" style="margin-top: 2rem;">
      <b-col cols="2">
        <div style="padding-left:1rem; font-size: 1rem;">{{rowData.length}} Allocations</div>
      </b-col>
      <b-col cols="9">
      </b-col>
      <b-col cols="1">
        <div align="right"><a><span style="color:black; margin-top:1rem; font-size: 1.2rem; font-weight: bold;" class="glyphicon glyphicon-save"></span></a>
        </div>
      </b-col>
        </b-row>-->
        <b-tabs content-class="mt-3">
          <b-tab title="Store Level" active>
            <p></p>
            <div v-if="getJsonData">
              <data-table
                :data="getJsonData.finalizeAllocation"
                :count="getJsonData.finalizeAllocation.length"
                :colDefs="CompletedAllocationColDef"
              >
                <template>
                  Actions:
                  <span>
                    <b-button
                      class="btn-download"
                      style="margin-left: 5px;"
                      @click="onDownloadClick"
                      download
                    >
                      Allocation Report
                      <i style="padding-left:0.25rem;" class="fa fa-download"></i>
                    </b-button>
                    <!-- <i
              v-b-tooltip.hover title="Download Allocations"
                class="fa fa-download cursor-pointer"
                style="margin-right:1rem;font-size: 1.1rem; color: #70BE00;font-weight: 500;"
                @click="onDownloadClick"
                    ></i>-->
                  </span>
                  <span>
                    <b-button
                      class="btn-download"
                      style="margin-left: 5px;"
                      @click="downloadSAp"
                      download
                    >
                      SAP Report
                      <i style="padding-left:0.25rem;" class="fa fa-download"></i>
                    </b-button>
                    <!-- <i
              v-b-tooltip.hover title="Download SAP Report"
                class="fa fa-download cursor-pointer"
                style="margin-right:1rem;font-size: 1.1rem; color: rgb(45, 16, 211);font-weight: 500;"
                @click="downloadSAp"
                    ></i>-->
                  </span>
                  <span>
                    <b-button
                      class="btn-download"
                      style="margin-left: 5px;"
                      @click="downloadAllocationReports"
                      download
                    >
                      Summary Report
                      <i style="padding-left:0.25rem;" class="fa fa-download"></i>
                    </b-button>
                    <!-- <i v-if="!getJsonData.Reports_Joann.length"
                v-b-tooltip.hover title="Download Summary Report"
                class="fa fa-download cursor-not-allowed"
                style="margin-right:1rem; color: #be0083;"
                    ></i>-->
                    <!-- <i v-else
                v-b-tooltip.hover title="Download Summary Report"
                class="fa fa-download cursor-pointer"
                style="margin-right:1rem; font-size: 1.1rem; color: #be0083;font-weight: 500;"
                @click="downloadAllocationReports"
                    ></i>-->
                  </span>
                  <!-- <span>
              <i
              disabled="getJsonData.Reports_Joann.length"
              v-b-tooltip.hover title="Download Report"
                class="fa fa-download cursor-pointer"
                style="margin-right:1rem;font-size: 1.1rem; color: #be0083;font-weight: 500;"
                @click="downloadAllocationReports"
              ></i>
                  </span>-->
                </template>
              </data-table>
            </div>
          </b-tab>
          <b-tab title="DC Level">
            <div v-if="getJsonData">
              <b-card>
                <div>
                  <data-table
                    :data="getDcLevelData"
                    :count="getDcLevelData.length"
                    :colDefs="FinalizeAllocationDCLevelColDef"
                    :autoResize="false"
                    :allowUpdateInDataTable="false"
                  >
                    <template slot-scope="prop">
                      <span>
                        <b-button
                          class="btn-download"
                          style="margin-left: 5px;"
                          @click="prop.downloadTable('DC_Level_Allocation')"
                          download
                        >
                          Download DC Level Allocation
                          <i
                            class="fa fa-download cursor-not-allowed"
                            style="padding-left:0.25rem; color:#73bd00!important"
                          ></i>
                        </b-button>
                      </span>
                    </template>
                  </data-table>
                </div>
              </b-card>
              <b-modal centered title="BootstrapVue" ok-only hide-header>
                <div align="middle">
                  <h5 class="my-4">This information was saved successfully.</h5>
                </div>
              </b-modal>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { format } from "date-fns";
import DataTable from "@/components/DataTable";
import CompletedAllocationColDef from "@/constants/tableDefinitions/CompletedAllocationColDef";
import FinalizeAllocationDCLevelColDef from "@/constants/tableDefinitions/FinalizeAllocationDCLevelColDef";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    AgGridVue
  },
  computed: {
    //getDcLevelData() {
    //   let returnedObj = [];
    //   this.getJsonData.strategy.forEach(element => {
    //     let tempObj = [];
    //     (tempObj = element),
    //       (tempObj.allocatedTillDate =
    //         element.inSeasonSales +
    //         element.storeTotalOH +
    //         element.storeTotalOO);

    //     let filtered = this.getJsonData.finalizeAllocation.filter(
    //       v =>
    //         v["Article ID"] == element.productID &&
    //         v.supplying_dc == element.supplyingDC
    //     );
    //     let storeIdsTemp = filtered.map(number => number["Allocation"]);
    //     const sum = storeIdsTemp.reduce(function(a, b) { return a + b; }, 0);
    //     tempObj.allocation = sum;
    //     tempObj.dcInventoryPostAllocation =
    //       element.availableInventory - tempObj.allocation;
    //     returnedObj.push(tempObj);
    //   });
    //   return returnedObj;
    // },

    isSummaryReportFlag() {
      let flag;
      if (this.getJsonData.Reports_Joann == undefined) {
        flag = false;
      } else {
        flag = true;
      }
      return flag;
    },
    createdOnDateFormat() {
      return format(this.getJsonData.allocationBasics.createdAt, "MM/DD/YYYY");
    },
    ...mapGetters({
      getJsonData: "CreateAllocation/getJsonData",
      getLoader: "CreateAllocation/getLoader",
      getSAPReports: "CreateAllocation/getSAPReports",
      getDownloadReport: "CreateAllocation/getDownloadReport"
    }),
    userName() {
      return localStorage.getItem("userName");
    }
  },
  methods: {
    ...mapActions({
      allocationByAllocationId: "CreateAllocation/allocationByAllocationId",
      downloadFinalizeAllocationReport:
        "CreateAllocation/downloadFinalizeAllocationReport",
      downloadSAPReports: "CreateAllocation/downloadSAPReports",
      AllocationReportDownload: "CreateAllocation/AllocationReportDownload"
    }),
    ...mapMutations({
      setJsonData: "CreateAllocation/setJsonData"
    }),
    downloadSAp(downloadSAP) {
      this.downloadSAPReports({
        allocationID: this.getJsonData.allocationBasics.allocationID
      });
    },
    downloadAllocationReports(donloadClick) {
      this.AllocationReportDownload({
        Reports_Joann: this.getJsonData.Reports_Joann,
        allocationID: this.getJsonData.allocationBasics.allocationID
      });
    },
    onDownloadClick() {
      this.downloadFinalizeAllocationReport({
        allocationID: this.getJsonData.allocationBasics.allocationID
      }).then(data => {
        let a2 = document.createElement("A");
        a2.href = data.data.url;
        a2.setAttribute("target", "_blank");
        document.body.appendChild(a2);
        a2.click();
        document.body.removeChild(a2);
      });
    }
  },
  mounted() {
    let returnedObj = [];
    let len = this.getJsonData.finalizeAllocation.length;
    let obj = {};

    for (let i = 0; i < len; i++) {
      let aa = this.getJsonData.finalizeAllocation[i];
      let xx = Number(aa["Article ID"]);
      let supplyxx = aa["Supplying DC"];
      if (typeof obj[xx + supplyxx] !== "undefined") {
        obj[xx + supplyxx].push(aa);
      } else {
        obj[xx + supplyxx] = [aa];
      }
    }
    let sum = 0;

    this.getJsonData.strategy.forEach(element => {
      let tempObj = {};
      tempObj = element;
      tempObj.allocatedTillDate =
        element.inSeasonSales + element.storeTotalOH + element.storeTotalOO;
      if (obj[Number(tempObj.productID) + tempObj.supplyingDC]) {
        sum = obj[Number(tempObj.productID) + tempObj.supplyingDC].reduce(
          function(a, b) {
            return parseInt(a) + parseInt(b.Allocation);
          },
          0
        );
      } else {
        sum = 0;
      }
      tempObj.allocation = sum;
      tempObj.dcInventoryPostAllocation =
        element.availableInventory - tempObj.allocation;
      returnedObj.push(tempObj);
    });

    this.getDcLevelData = returnedObj;
    returnedObj = [];
  },
  watch: {
    getSAPReports(val) {
      let a = document.createElement("A");
      a.href = val.data.url;
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getDownloadReport(val) {
      let a = document.createElement("A");
      a.href = val.data.url;
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  },
  components: {
    DataTable
  },
  destroyed() {
    this.setJsonData(null);
  },
  data() {
    return {
      getDcLevelData: null,
      FinalizeAllocationDCLevelColDef,
      CompletedAllocationColDef
    };
  },
  created() {
    // this.allocationByAllocationId({allocationID: "3"})
  }
};
</script>

<style scoped>
.btn-download {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: rgb(255, 255, 255) !important;
}
.btn-download:hover {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: rgb(202, 213, 219) !important;
}
</style>
